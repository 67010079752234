<script setup>
import { ref, computed, onMounted } from 'vue';
import { changeInfo, signIn } from '@/api/auth/auth.js';
import { useRouter } from 'vue-router';
import { changePasswordData } from '@/composables/changePassword';

const router = useRouter();
const { 
    getUid,
    getToken
} = changePasswordData();

const newPassword = ref(null);
const confirmedPassword = ref(null);

const passwordRegex = /^((?=\S*?[A-Za-z])(?=\S*?[0-9]).{7,16})\S$/;

const passwordConfirmed = computed(() => newPassword.value ? newPassword.value == confirmedPassword.value : true);
const passwordValid = computed(() => passwordRegex.test(newPassword.value));
const saveButtonText = computed(() => passwordSaving.value ? 'Сохранение...' : 'Сохранить');

const alertVisible = computed(() => !passwordConfirmed.value || !passwordValid.value);
const error = ref(false);

const alert = computed(() => {
    if (!passwordValid.value)
        return `Пароль должен содержать от 8 до 16 символов и как минимум 1 цифру и 1 букву.
        \nИспользование кириллицы запрещено`;

    if (!passwordConfirmed.value)
        return 'Пароли не совпадают';

    if (error.value) {
        return 'Произошла ошибка. Попробуйте еще раз';
    }
    return null;
});

const passwordSaving = ref(false);
async function savePassword() {
    if (alertVisible.value)
        return;

    if (passwordSaving.value)
        return;

    passwordSaving.value = true;
    let result = await changeInfo(
        getUid(), 
        getToken(), 
        { password: newPassword.value }
    );

    if (result) {
        await login(result.data.token);
    }
    passwordSaving.value = false;
}

async function login(token) {
    let result = await signIn(token);
    if (result == 200) {
        error.value = false;
        router.replace('/');
    } else {
        error.value = true;
    }
}

onMounted(() => {
    if (!getUid() || !getToken()) {
        router.push('/login');
    }
});
</script>

<template>
    <div class="change-pass d-flex flex-column">
        <div class="change-pass__content d-flex flex-column justify-content-between">
            <h4 class="change-pass__title text-center">
                Ввод нового пароля
            </h4>
            <div class="change-pass__new-pass form-floating">
                <input 
                    class="change-pass__new-pass-input form-control" 
                    id="newPass" 
                    v-model="newPassword" 
                    type="password"
                    placeholder="Новый пароль" 
                    autofocus/>
                <label for="newPass">Новый пароль</label>
            </div>
            <div class="change-pass__confirm-pass form-floating">
                <input 
                    class="change-pass__confirm-pass-input form-control"
                    id="confirmPass" 
                    v-model="confirmedPassword" 
                    type="password"
                    placeholder="Подтверждение пароля"/>
                <label for="confirmPass">Подвердите пароль</label>
            </div>
            <button 
                class="change-pass__save-button btn btn-primary btn-lg"
                type="button" 
                @click="savePassword"
                :disabled="alertVisible">
                <span v-if="passwordSaving" 
                    class="change-pass__spinner spinner-grow spinner-grow-sm" 
                    role="status" 
                    aria-hidden="true"/>
                {{ saveButtonText }}
            </button>
        </div>
        <div class="change-pass__alert-layout mt-auto">
            <p v-show="alertVisible"
                class="change-pass__alert mb-0 text-center text-danger">
                {{ alert }}
            </p>
        </div>
    </div>
</template>

<style scoped>
.change-pass {
    width: 100%;
    height: 364px;
}
.change-pass__content {
    height: 80%;
}

.change-pass__alert-layout {
    max-height: 15%;
}

.change-pass__alert {
    font-size: clamp(0.75rem, 0.681rem + 0.37vw, 1.125rem);
    line-height: normal;
}

@media screen and (max-width: 600px) {
    .change-pass {
        height: auto;
    }
}
</style>